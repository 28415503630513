<template>
  <v-card class="fill-height" tile>
    <v-toolbar flat dark color="primary" max-height="65px">
      <v-toolbar-title> Solicitações </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text v-if="request.answered == false">
      <v-container>
      <v-card class="pa-5 elevation-0"
      style="border-bottom: solid 3px #008390; border-top: solid 3px #008390">
        <v-sheet class="d-flex align-center pb-3" width="100%">
            <span style="font-weight: 500" class="mr-1">Título:</span>{{ request.title }}
          <v-spacer />
          <span style="font-weight: 500">Categorias: </span>
          <div
            v-for="(categories, i) in request.categories"
            :key="i"
            class="d-flex allign center">
            <v-icon :color="categories.color" small class="pl-1"
              >mdi-square</v-icon
            >{{ categories.name }}
          </div>
        </v-sheet>
        <v-divider/>
        <v-sheet class="pt-3">
            <span style="font-weight: 500" class="mr-1">Descrição:</span>{{ request.description }} 
        </v-sheet>
      </v-card>
      <v-divider />
      <v-card
        class="pa-5 my-3"
        style="border-left: 5px solid #008390"
        v-for="(doc, i) in request.documents"
        :key="i">
        <div class="d-flex mb-3">
          <span class="pr-1 primary--text">Nome: </span>
          {{ doc.name }}
          <v-spacer />
          <span class="pr-1 primary--text">Tipos: </span>
          <template v-for="(types, i) in doc.allowed_types">
            {{ types }}{{ comma(doc.allowed_types, i) }}
          </template>
          <v-spacer />
          <span class="primary--text">Categorias: </span>
          <v-sheet
            v-for="(categories, ind) in doc.categories"
            :key="ind"
            class="d-flex allign center">
            <v-icon :color="categories.color" small class="pl-1"
              >mdi-square</v-icon
            >{{ categories.name }}
          </v-sheet>
        </div>
        <v-divider />
        <div class="d-flex pt-3">
          <p
            class="mt-3"
            v-if="doc.description != '' && doc.description != undefined"
            style="word-wrap: break-word; max-width: 72%">
            <span class="pr-1 primary--text">Descrição: </span>
            {{ doc.description }}
          </p>
          <v-spacer/>
          <v-btn v-show="doc.filepath" small class="primary my-auto mr-2" @click="$confirmDialog('Deseja mesmo reenviar o arquivo?', 'Ao clicar em reenviar o arquivo anterior será perdido.', () => clearDocument(i))">
            Reenviar
          </v-btn>
          <div class="my-auto green--text" v-show="doc.filepath"><v-icon small color="green">mdi-check</v-icon> {{ docStatus(doc) }}</div>
        </div>
        <div class="d-flex" v-if="docStatus(doc) != 'Enviado' || doc.editar">
          <v-file-input
            v-model="doc.file"
            color="primary"
            accept="image/*"
            counter
            hide-details
            label="Selecionar Arquivo"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000">
            <template v-slot:selection="{ text }">
              <v-chip color="primary" dark label small>
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-btn @click="sendFile(doc, doc.file)" :loading="doc.loading" :disabled="doc.file == null || doc.filepath != null" color="success">
            Confirmar
            <v-icon color="white">mdi-upload</v-icon>
          </v-btn>
        </div>
      </v-card>
      </v-container>
    </v-card-text>
    <v-card-text v-else class="text-center">
      <h2 class="pt-16">Essa solicitação não existe ou já foi respondida</h2> 
    </v-card-text>
    <v-card-actions class="justify-center" v-show="request.answered == false"><v-btn class="green white--text" :disabled="!allDocumentsAnswered()" @click="confirmRequestResponse(); request.answered = true;">Confirmar o envio de todos os arquivos</v-btn></v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RequestResponse",
  data: () => ({
    loading: false,
    request: {documents: {file: [], loading: false}},
    resendDocumentIndex: 0,
  }),
  methods: {
    docStatus(doc){
      if(doc.filepath) return "Enviado"
      else return null;
    },
    clearDocument(i){
      this.request.documents[i].filepath = null;
    },
    sendFile(doc, file){
      this.loading = true;
      let formData = new FormData()
      formData.append('document', file)
      this.$axios
        .post("/document/" + this.request.code + "/" + doc.id, formData)
        .then(() => {
          this.getRequest();
          this.$showMessage("success", "Arquivo Adicionado");
        })
        .catch((error) => {
          this.$showMessage("error", "Falha Ao enviar o Arquivo");
        })
        .finally(() => {
          doc.answered = true;
          this.loading = false;
        });
    },
    getSituation(item) {
      if (item.canceled) return "Cancelada";
      else if (item.answered) return "Respondida";
      else return "Pendente";
    },

    getRequest() {
      this.$axios
        .get("/form_request/outer/" + this.$store.state.route.params.code)
        .then((response) => {
          this.request = response.data;
       
        })
        .catch((error) => {
          switch (error.response.status) {
           case 401:
             this.$showMessage("warning", "Sem autorização para visualizar a Solicitação.");
             break;
           case 404:
             this.$showMessage("warning", "A Solicitação não foi Encontrada");
             break;
           case 422:
             this.$showMessage("warning", "Erro ao validar a Solicitação");
             break;
           default:
             this.$showMessage("error", "Erro ao carregar a Solicitação");
             break;
         }}).finally(() => {
          this.loading = false;
        });
    },
    allDocumentsAnswered(){
      let answered = 0;
      for(let i = 0; i < this.request.documents.length; i++){
        if(this.request.documents[i].filepath) answered++;
      }
      if (answered == this.request.documents.length)
      return true;
      else return false;
    },
    confirmRequestResponse(){
      if(this.allDocumentsAnswered()){
       this.$axios
        .post("/form_request/" + this.$store.state.route.params.code)
        .then(() => {
          this.$showMessage("success", "Solicitação Respondida.");
          })
        .catch((error) => {
        switch (error.response.status) {
           case 404:
             this.$showMessage("error", "A Solicitação não foi Encontrada");
             break;
           default:
             this.$showMessage("error", "Erro ao Responder a Solicitação");
             break;
             }
          })
          .finally(() => {
            this.loading = false;
          });
        } else{
          this.$showMessage("warning", "Todos os Documentos devem ser Enviados.")
        }
    },
    comma(item, index) {
      var max = item.length;
      if (index < max - 1) {
        return ", "; 
      }
    },
  },

  mounted() {
    this.getRequest();
    this.getFileTipes();
  },
};
</script>
